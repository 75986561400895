.headerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 10;
    border-bottom: #555;
    gap: var(--spacing-md);

    @media (min-width: 768px) {
        margin: auto;
        max-width: 1600px;
        height: max-content;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: var(--secondary);
        width: 100%;        
    }
}

.topRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: var(--header-padding);
}

.divider {
    height: 3px;
    width: 95%;
    background-color: #000; /* Change this to match your design */
}

.navigation {
    position: relative;
    display: flex;
    flex-wrap: column;
    gap: var(--spacing-xl);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: var(--secondary);
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s;
    z-index: 9;
}

.overlay.open {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
    animation: fade-in 0.3s forwards;
}

.overlay:not(.open) {
    animation: fade-out 0.3s forwards;
    transition-delay: 0;
}

.overlay ul {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
}

.overlay li {    
    color: var(--primary)
}

.hamburger {
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: var(--secondary);
    padding: var(--header-padding);
    width: 100%;
}