.landingView {
    overflow-y: hidden;
}

.content {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: auto;
    align-items: center;
    grid-gap: var(--spacing-xl);
    margin-top: 20px;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-lg);
    order: 2;
}

.profileImage {
  border-radius: 20%;
  max-height: 300px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  order: 1;
}

.title {
  opacity: 0;
  animation: fadeIn 1s 1s ease-in forwards;
}

.title > * {
  font-size: var(--font-main-title-sm);
}

.subtitle {
  opacity: 0;
  animation: bounce 1s 3s ease-in forwards;
}

.extraInfo {
  opacity: 0;
  display: flex;
  gap: var(--spacing-lg);
  margin-top: 12px;
  animation: fadeIn 1s 4s ease-in forwards;
}

.learnMore, .contact {
  color: var(--tertiary);
  text-decoration: underline;
}

@media (min-width: 768px) {
  .content {
    grid-template-columns: 1fr 1fr;
    grid-gap: 64px;
  }

  .profileImage {
    max-height: 600px;
  }

  .imageContainer {
    order: 2;
  }

  .infoContainer {
    order: 1;
  }

  .title > * {
    font-size: var(--font-main-title);
  }
}

@keyframes fadeIn {  
  to {  
      opacity: 1;
  }  
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  60% {
    opacity: 0.5;
    transform: translateY(-5%);
  }
  80% {
    opacity: 0.75;
    transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

