.contactView {
    align-items: center;
}

.content {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-xl);
}

.content > div:first-child, .tabletContent > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 90px;
}

.tabletContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 90px;
    margin-top: 40px;
}

.emailContent, .linkContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media screen and (max-width: 1023px) {
    .tabletContent > div:last-child {
        align-items: center;
    }

    .emailContent, .linkContent {
        align-items: center;
    }
}

.emailHeader {
    color: var(--primary);
    text-decoration: underline;
    margin-bottom: 12px;
}

.emailDesc {
    color: var(--primary);
    word-break: break-word;
}

.iconList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 53px;
}

.iconList svg path {
    fill: var(--primary);
}

.newsLetterContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 74px 34px;
    background: var(--primary);
    border-radius: 10px;
    max-width: 479px;
    max-height: 361px;
    gap: 16px;
}

.newsletterDesc {
    color: var(--primary);
}

.input {
    background: var(--primary);
    border-radius: 20px;
    max-width: 649px;
    width: 100%;
    height: 77px;
}