.contactAndIcons {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-md);
    margin-top: var(--spacing)
}

@media (min-width: 768px) {
    .contactAndIcons {
        flex-direction: row;
        gap: var(--spacing-lg);
        margin-top: 0;
    }
}

.contact {
    background-color: var(--primary);
    color: var(--secondary);
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.contact:hover {
    background-color: #555;
    color: var(--secondary);
}

.iconList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 16px;
}

.iconList svg {
    width: 24px;
    height: 24px;
    transition: fill 0.3s ease;
}

.iconList svg path {
    fill: var(--primary);
}

.iconList svg:hover path {
    fill: #555;
}