.content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
}

.section {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--spacing-xl);
    align-items: center;
}

.section:last-child {
    grid-template-columns: 1fr;
}

.section img {
    border-radius: 20px;
    max-width: 600px;
    max-height: 400px;
    width: 100%;
}

.section:first-child img {
    object-fit: cover;
    object-position: 50% 20%;
}

.section p {
    text-align: left;
}

.section:last-child .textContainer {
    align-items: center;
}

.desktopImageContainer {
    display: none;
}

.imageContainer {
    display: flex;
    justify-content: center;
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-lg);
}

.divider {
    width: 100%;
    height: 2px;
    background-color: var(--primary);
}

@media (min-width: 1024px) {
    .section {
        grid-template-columns: 1fr 1fr;
    }

    .section:first-child img {
        height: 400px;
    }

    .desktopImageContainer {
        display: flex;
        justify-content: center;
    }

    .desktopImageContainer:first-child {
        justify-content: flex-start;
    }

    .imageContainer {
        display: none;
    }

    .divider {
        display: none;
    }
}