.articleCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 26px;
    gap: 10px;
    background: var(--primary);
    border-radius: 20px;
    width: 100%;
    max-width: 475px;
    min-height: 350px;
}

.imageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.imageContainer img {
    max-height: 175px;
    width: auto;
}

.titleDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.title {
    text-transform: capitalize;
}

.title, .date {
    color: var(--secondary);
}

@media screen and (max-width: 768px) {
    .imageContainer img {
        max-height: 150px;
    }
}

@media screen and (max-width: 480px) {
    .articleCard {
        min-height: 300px;
    }
}