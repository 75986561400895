.view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 134px 40px 40px 40px;
    margin: auto;
    max-width: 1600px;
}

@media (min-width: 768px) {
  .view {
    padding: 142px 40px 40px 40px;
  }  
}