@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.articleView {
    align-items: center;
    justify-content: center;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xl);
}

.imageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.imageContainer img {
    max-height: 480px;
    width: auto;
}

.articleContent {
    display: flex;
    max-width: 800px;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-lg);
}

.titleDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.articleText {
    text-align: left;
}

.articleText h4 {
    font-size: var(--font-sm);
    font-family: "Poppins";
    font-weight: 400;
    text-align: center;
    padding: var(--spacing-lg) 0;
}

.articleText p {
    font-size: var(--font-xs);
    font-family: "Poppins";
    line-height: normal;
    padding: var(--spacing-lg) 0;
}

.articleText a {
    text-decoration: underline;
    color: var(--link);
}

@media (min-width: 768px) {
    .articleText h4 {
        font-size: var(--font-md);
    }

    .articleText p {
        font-size: var(--font-sm);
    }
}