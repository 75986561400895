:root {
  /* colors */
  --primary: #000	;
  --secondary: #fff;
  --background: radial-gradient( circle at 15% 27.9%,  #226dc891 12.9%, rgba(133, 76, 86, 0.44) 91.2% );
  --link: #1825df;
  --tertiary: #817777;
  --header-padding: 40px 40px 20px 40px;
  --footer-padding: 20px;

  /* font */
  --font-family: 'Poppins', sans-serif;
  
  /* font-size */
  --font-main-title: 3.6rem;
  --font-main-title-sm: 2.6rem;
  --font-xxl: 2.2rem;
  --font-xl: 2rem;
  --font-l: 1.9rem;
  --font-md: 1.7rem;
  --font-sm: 1.5rem;
  --font-xs: 1.2rem;

  /** spacing */
  --spacing-xxl: 40px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 8px;
}

#root {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
